import PropTypes from 'prop-types';
import React, { PureComponent, useEffect, useState } from 'react';
import Input, { THEMES } from 'components/Input/AppInput';
import './AutoSuggestInput.scss';

export { THEMES } from 'components/Input/AppInput';

export const MATCHING = {
    START_WITH: 0,
    CONTAINS: 1
};

export default class AutoSuggestInput extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        dropdownClassName: PropTypes.string,
        theme: PropTypes.oneOf(Object.keys(THEMES).map(key => THEMES[key])),
        inputProps: PropTypes.object,
        selection: PropTypes.shape({
            value: PropTypes.string.isRequired,
            isSelected: PropTypes.bool.isRequired
        }),
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired
        })),
        forceDropOnFocus: PropTypes.bool,
        right: Input.propTypes.right,
        left: Input.propTypes.left,
        disabled: PropTypes.bool,
        isShowAllOptions: PropTypes.bool,
        matching: PropTypes.number,
        // to limit the user with only the given options
        disableManualInput: PropTypes.bool
    }

    static defaultProps = {
        className: '',
        dropdownClassName: '',
        inputClassName: '',
        inputProps: {},
        theme: THEMES.PRIMARY,
        matching: MATCHING.START_WITH,
        disableManualInput: false
    }

    constructor(props) {
        super(props);
        this.rootNode = null;
        this.state = {
            drop: false,
            forceDrop: false
        };
    }

    componentDidMount() {
        window.addEventListener('click', this.handleWindowClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleWindowClick);
    }

    MIN_LENGTH_TO_SUGGEST = 1;
    MAX_SUGGESTIONS = 4;

    handleWindowClick = e => {
        const { onChange, disableManualInput } = this.props;
        const { target } = e;
        const rootNode = this.rootNode;

        if (rootNode && !rootNode.contains(target)) {
            this.setState({
                drop: false,
                forceDrop: false
            });
        }

        // to remove input data if the user didn't select any options
        if (disableManualInput && rootNode && !this.props.selection.isSelected) {
            onChange({
                value: '',
                isSelected: false
            });
        }
    }

    getSuggestions = () => {
        const { forceDrop } = this.state;
        const { options, selection, isShowAllOptions, matching } = this.props;
        const { value } = selection;
        let filteredOpt = [];

        if (value?.length >= this.MIN_LENGTH_TO_SUGGEST) {
            filteredOpt = options.filter(option => {
                const inputValue = value?.toLowerCase();
                const label = option.label?.toLowerCase();

                switch (matching) {
                    case MATCHING.CONTAINS:
                        return label?.includes(inputValue);
                    case MATCHING.START_WITH:
                    default:
                        return label?.startsWith(inputValue);
                }
            });
        } else {
            filteredOpt = forceDrop ? options : [];
        }

        return isShowAllOptions ? filteredOpt : filteredOpt.slice(0, this.MAX_SUGGESTIONS);
    }

    handleSelect = id => () => {
        const { onChange, options } = this.props;

        const option = options.find(option => option.id === id);

        onChange({
            id,
            value: option.label,
            isSelected: true
        }, option);

        this.setState({ drop: false, forceDrop: false });
    }

    handleChange = e => {
        const { onChange } = this.props;
        const value = e.currentTarget.value;

        onChange({
            value,
            isSelected: false
        });
    }

    handleFocus = () => {
        const { forceDropOnFocus, onChange } = this.props;

        this.setState(({ forceDrop }) => ({
            drop: true,
            forceDrop: forceDropOnFocus || forceDrop,
            value: ''
        }));

        onChange({
            value: '',
            isSelected: false
        });
    }

    handleRightClick = e => this.setState({
        forceDrop: true
    });

    render() {
        const { drop, forceDrop } = this.state;
        const {
            className = '',
            dropdownClassName,
            inputProps,
            theme,
            selection,
            placeholder,
            onChange,
            options,
            right,
            left,
            disabled,
            isShowAllOptions,
            ...others
        } = this.props;

        const suggestions = this.getSuggestions();

        const { value } = selection;

        const dropDown = (drop || forceDrop);

        return (
            <span
                ref={node => (this.rootNode = node)}
                {...others}
                className={`
                    AutoSuggestInput
                    ${className}
                `}>
                <Input
                    {...inputProps}
                    disabled={disabled}
                    className={`
                        AutoSuggestInput__input
                        ${inputProps.className}
                    `}
                    autoComplete={false}
                    theme={theme}
                    value={value}
                    right={right}
                    left={left}
                    onRightClick={this.handleRightClick}
                    placeholder={placeholder}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus} />
                {dropDown && !disabled && (
                    <div
                        className={`
                            AutoSuggestInput__dropdown
                            ${dropdownClassName}
                        `} >
                        {suggestions && suggestions.map(suggestion => (
                            <span
                                key={suggestion.id}
                                className='AutoSuggestInput__dropdown-item'
                                onClick={this.handleSelect(suggestion.id)}>
                                {suggestion.label}
                            </span>
                        ))}
                    </div>
                )}
            </span>
        );
    }
}

export const EnhancedAutoSuggestionInput = ({ onChange, value, forceSelected, ...props }) => {
    const [selected, setSelected] = useState({ isSelected: false, value });
    const { isShowAllOptions, dropdownClassName = '' } = props;

    const onHandleChange = ({ isSelected, value }, option) => {
        const selected = { selected: isSelected ? value : '', isSelected: isSelected && !!value, value };

        setSelected(selected);
        onChange(selected, option);
    };

    useEffect(() => {
        setSelected((oldVal) => ({
            ...oldVal,
            isSelected: forceSelected || oldVal.isSelected,
            value: value
        }));
    }, [forceSelected, value]);

    return (
        <AutoSuggestInput
            {...props}
            isShowAllOptions={isShowAllOptions}
            dropdownClassName={isShowAllOptions ? `${dropdownClassName} -show-all-option` : dropdownClassName}
            onChange={onHandleChange}
            selection={selected} />
    );
};

EnhancedAutoSuggestionInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    forceSelected: PropTypes.bool,
    isShowAllOptions: PropTypes.bool,
    dropdownClassName: PropTypes.string
};

EnhancedAutoSuggestionInput.defaultProps = {
    onChange: () => {},
    forceSelected: false,
    dropdownClassName: '',
    value: ''
};
