import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppLoading from 'containers/App/components/Loading/AppLoading';
import {
    ROUTES,
    ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE,
    ROUTE_REDIRECT_FROM_AUTHED_ROUTE
} from 'config/constants';

/*
 * Route only for authed clients
 */
export class AuthedRoute extends Component {
    static propTypes = {
        component: PropTypes.object.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        hasCompany: PropTypes.bool.isRequired,
        requiresCompany: PropTypes.bool.isRequired,
        path: PropTypes.string.isRequired,
        location: PropTypes.object
    }

    shouldComponentUpdate(nextProps) {
        const { isAuthed, hasCompany, requiresCompany, path } = this.props;

        if (isAuthed !== nextProps.isAuthed ||
            hasCompany !== nextProps.hasCompany ||
            requiresCompany !== nextProps.requiresCompany ||
            path !== nextProps.path) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed, hasCompany, requiresCompany, location, ...props } = this.props;

        if (requiresCompany && !hasCompany) {
            return <Redirect to={ROUTES.COMPANY_CREATION}/>;
        }

        if (isAuthed) {
            return (
                <Route {...props} />
            );
        }

        return (
            <Redirect to={{
                pathname: ROUTE_REDIRECT_FROM_AUTHED_ROUTE,
                state: { from: location }
            }} />
        );
    }
}

/*
 * Route only for un-authed clients
 */
export class UnauthedRoute extends Component {
    static propTypes = {
        component: PropTypes.object.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        path: PropTypes.string.isRequired,
        location: PropTypes.object
    }

    shouldComponentUpdate(nextProps) {
        const { isAuthed, path } = this.props;

        if (isAuthed !== nextProps.isAuthed ||
            path !== nextProps.path) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed, location, ...props } = this.props;

        if (!isAuthed) {
            return (
                <Route {...props} />
            );
        }

        return (
            <Redirect to={{
                pathname: ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE,
                state: { from: location }
            }} />
        );
    }
}

/*
 * Redirect to external url
 */
export class ExternalRedirect extends Component {
    static propTypes = {
        from: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired
    }

    routeRender = () => {
        window.location = this.props.to;

        return <AppLoading />;
    }

    render() {
        const { from, to, ...props } = this.props;

        return <Route {...props} render={this.routeRender} />;
    }
}
