import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';
import discountTag from 'assets/images/discount-tag.png';
import './Card.scss';

export const ICON_SIZES = {
    SM: 'sm',
    MD: 'md'
};

export default class Card extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        image: PropTypes.string,
        imageClassname: PropTypes.string,
        label: PropTypes.string,
        description: PropTypes.string,
        discount: PropTypes.string,
        isAdd: PropTypes.bool,
        isLoading: PropTypes.bool,
        disabled: PropTypes.bool,
        hasOptions: PropTypes.bool,
        onUninstall: PropTypes.func,
        onClick: PropTypes.func,
        highlighted: PropTypes.bool,
        roundImage: PropTypes.bool,
        displayOptionsHover: PropTypes.bool,
        small: PropTypes.bool,
        rounded: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.shape({
            icon: PropTypes.object,
            text: PropTypes.string,
            onClick: PropTypes.func
        })),
        iconSize: PropTypes.oneOf(Object.keys(ICON_SIZES).map(key => ICON_SIZES[key]))
    }

    static defaultProps = {
        className: ''
    }

    constructor(props) {
        super(props);
        this.optionsNode = null;
        this.state = {
            isOptionOpen: false,
            mouseIn: false
        };
    }

    componentDidMount() {
        window.addEventListener('click', this.handleWindowClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleWindowClick);
    }

    handleWindowClick = e => {
        const { target } = e;
        const optionsNode = this.optionsNode;

        if (optionsNode && !optionsNode.contains(target)) {
            this.setState({
                isOptionOpen: false
            });
        }
    }

    handleOptionsToggle = () => this.setState({
        isOptionOpen: !this.state.isOptionOpen
    })

    handleOptionsClose = () => this.setState({
        isOptionOpen: false
    })

    onMouseEnter = () => {
        this.setState({ mouseIn: true });
    }

    onMouseLeave = () => {
        this.setState({ mouseIn: false });
    }

    render() {
        const { isOptionOpen, mouseIn } = this.state;
        const {
            className = '',
            image,
            id,
            label,
            description,
            discount,
            isAdd,
            isLoading,
            disabled,
            hasOptions,
            onClick,
            highlighted,
            options,
            roundImage,
            displayOptionsHover = false,
            small,
            rounded,
            imageClassname,
            iconSize,
            ...others
        } = this.props;

        return (
            <div
                {...others}
                className={`
                    Card
                    Card--icon-${iconSize}
                    ${isAdd ? 'Card--add' : ''}
                    ${disabled ? 'Card--disabled' : ''}
                    ${onClick ? 'Card--isClickable' : ''}
                    ${isLoading ? 'Card--isLoading' : ''}
                    ${highlighted ? 'Card--highlighted' : ''}
                    ${roundImage ? 'Card--roundImage' : ''}
                    ${small ? 'Card--small' : ''}
                    ${className}
                `}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onClick={!disabled && onClick}>
                <div
                    className={`
                        Card__logo
                        ${small ? 'Card__logo--small' : ''}
                    `}>
                    {isLoading ? <ActivityIndicator size='40' className='Card__logo-loading' />
                        : isAdd ? <FontAwesomeIcon icon={faPlus} /> : (
                            <img
                                className={`
                                ${imageClassname}
                            Card__logo-image
                            ${rounded ? 'Card__logo-image-rounded' : ''}`}
                                src={image}
                                alt={label} />
                        )}
                </div>
                <div className='Card__text'>
                    {label && (
                        <p className='Card__label'>
                            {label}
                        </p>
                    )}
                    {description && (
                        <p className='Card__description'>
                            {description}
                        </p>
                    )}
                    {discount && (
                        <div className='Card__discount'>
                            <img src={discountTag} alt='discount'/>
                            <p>
                                {discount}
                            </p>
                        </div>
                    )}
                </div>
                {!disabled && (displayOptionsHover
                    ? mouseIn
                    : hasOptions) && (
                    <span
                        onClick={(e) => { e.stopPropagation(); }}
                        ref={node => (this.optionsNode = node)}
                        className='Card__options'>
                        <FontAwesomeIcon
                            className='Card__options-icon'
                            icon={faEllipsisV}
                            onClick={this.handleOptionsToggle} />
                        {isOptionOpen && (
                            <div className='Card__options-items'>
                                {options.map(option => (
                                    <div className='Card__options-items-item' key={option.text} onClick={() => { option.onClick(id); this.setState({ isOptionOpen: false }); }}>
                                        <FontAwesomeIcon
                                            icon={option.icon}/>
                                        <span
                                            className='Card__options-items-item-label'>
                                            {option.text}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </span>
                )}
            </div>
        );
    }
}
