import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './AppInput.scss';

export const STATUS_TYPES = {
    VALID: 'VALID',
    INVALID: 'invalid'
};

export const THEMES = {
    PRIMARY: 'primary',
    GRAY: 'gray',
    LIGHT_GRAY: 'light-gray',
    TRANSPARENT_GRAY: 'transparent-gray',
    PRIMARY_BORDER: 'primary-border',
    BORDERLESS: 'borderless',
    BLUE: 'blue',
    CARD: 'card',
    WHITE_BORDERED: 'white-bordered'
};

class AppInputWithoutRef extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        inputClassName: PropTypes.string,
        component: PropTypes.elementType,
        theme: PropTypes.oneOf(Object.keys(THEMES).map(key => THEMES[key])),
        noFocus: PropTypes.bool,
        readOnly: PropTypes.bool,
        noBorder: PropTypes.bool,
        inputProps: PropTypes.object,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.string,
        underlined: PropTypes.bool,
        name: PropTypes.string,
        type: PropTypes.string,
        disabled: PropTypes.bool,
        defaultValue: PropTypes.any,
        required: PropTypes.bool,
        autoComplete: PropTypes.oneOf(['on', 'off']),
        maxLength: PropTypes.number,
        left: PropTypes.node,
        right: PropTypes.node,
        onRightClick: PropTypes.func,
        onLeftClick: PropTypes.func,
        innerRef: PropTypes.object,
        inputRightClassName: PropTypes.string,
        textAreaAutoResize: PropTypes.bool
    }

    static defaultProps = {
        className: '',
        inputClassName: '',
        theme: THEMES.PRIMARY,
        textAreaAutoResize: false
    }

    constructor(props) {
        super(props);
        this.input = null;
        this.state = {
            isFocused: false
        };
    }

    handleFocusChange = isFocused => () => this.setState({
        isFocused
    })

    handleClick = () => this.input.focus();

    handleRef= node => {
        const { innerRef } = this.props;

        this.input = node;
        innerRef && innerRef(node);
    }

    // to auto grow textarea
    handleKeyDown = (e) => {
        if (this.props.component === 'textarea' && this.props.textAreaAutoResize) {
            e.target.style.height = 'inherit';
            // additional 20 px for compensation
            e.target.style.height = `${e.target.scrollHeight > 70 ? e.target.scrollHeight + 20 : e.target.scrollHeight}px`;
        }
    }

    render() {
        const {
            className,
            inputClassName,
            component,
            theme = THEMES.PRIMARY,
            noFocus,
            noBorder,
            readOnly,
            inputProps,
            placeholder,
            onChange,
            value,
            name,
            disabled,
            autoComplete,
            defaultValue,
            required,
            maxLength,
            type,
            left,
            right,
            onRightClick,
            onLeftClick,
            underlined,
            innerRef,
            inputRightClassName,
            autoFocus,
            ...others
        } = this.props;
        const { isFocused } = this.state;

        const Component = component || 'input';

        return (
            <span
                {...others}
                className={`
                    ${className}
                    AppInput
                    AppInput--${theme}
                    ${isFocused ? 'AppInput--focused' : ''}
                    ${!noFocus ? 'AppInput--focus' : ''}
                    ${!noBorder ? 'AppInput--border' : ''}
                    ${disabled ? 'AppInput--disabled' : ''}
                    ${underlined ? 'AppInput--underlined' : ''}
                `}
                onClick={this.handleClick} >
                {!!left && (
                    <span
                        className='AppInput__left'
                        onClick={onLeftClick}>
                        {left}
                    </span>
                )}
                <div className='AppInput__input-container'>
                    <Component
                        {...inputProps}
                        ref={this.handleRef}
                        className={`
                            ${inputClassName}
                            ${inputProps ? inputProps.className : ''}
                            AppInput__input-container-input
                        `}
                        placeholder={placeholder}
                        onChange={readOnly ? undefined : onChange}
                        value={value}
                        name={name}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        required={required}
                        autoComplete={autoComplete}
                        type={type}
                        maxLength={maxLength}
                        onFocus={this.handleFocusChange(true)}
                        onBlur={this.handleFocusChange(false)}
                        autoFocus={autoFocus}
                        onKeyDown={this.handleKeyDown}/>
                </div>
                {!!right && (
                    <span
                        className={`AppInput__right ${inputRightClassName}`}
                        onClick={onRightClick}>
                        {right}
                    </span>
                )}
            </span>
        );
    }
}

const AppInput = React.forwardRef((props, ref) => (
    <AppInputWithoutRef
        innerRef={ref}
        {...props}/>
));

AppInput.propTypes = AppInputWithoutRef.propTypes;

export default AppInput;
