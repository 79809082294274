import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Copy from '../../../../../assets/icons/copy.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCheckSquare, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { getReferredUser } from 'api/coach';
import { format } from 'date-fns';

ReferralCode.propTypes = {
    account: PropTypes.object,
    selectedCompany: PropTypes.object,
    toggleReferralCode: PropTypes.func.isRequired
};

export default function ReferralCode({ account, selectedCompany, toggleReferralCode }) {
    const [copySuccess, setCopySuccess] = useState('');
    const [referralUsers, setReferralUser] = useState([]);
    const [referralUserNumber, setReferralUserNumber] = useState(0);

    const referralCode = selectedCompany?.coach_profile?.referral?.referral_code;

    const copyToClipBoard = async referralCode => {
        try {
            await navigator.clipboard.writeText(referralCode);
            setCopySuccess('Copied');
        } catch (err) {
            setCopySuccess('Failed to copy');
        }
    };

    const loadReferralCodeUser = async () => {
        try {
            const res = await getReferredUser(account.id);

            setReferralUser(res ? res.data.data : []);
            setReferralUserNumber(res ? res.data.data.length : 0);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        loadReferralCodeUser();
    }, []);

    return (
        <div className='bm-item ReferralCode__menu'>
            <p className='ReferralCode__menu-title'>
                <FontAwesomeIcon className='ReferralCode__menu-back' icon={faChevronLeft} color='#fff'
                    size={'sm'} onClick={toggleReferralCode} />
                    Referral Code
            </p>
            <div className='ReferralCode__content'>
                <p className='ReferralCode__subtitle'>The code refers to you as a coach in the company</p>
                <div className='d-flex ReferralCode__company'>
                    <img className='ReferralCode__company-image' alt='Avatar' src={selectedCompany.logo}/>
                    <p className='ReferralCode__company-name'>{selectedCompany.name}</p>
                </div>
                <div className='ReferralCode__data'>
                    <div className='d-flex'>
                        <div className='ReferralCode__data-title'>
                            <p>Code</p>
                        </div>
                        <div className='ReferralCode__data-code'>
                            <button className='d-flex' onClick={() => copyToClipBoard(referralCode)}>
                                <img alt='Copy' src={Copy}/>
                                <p>{referralCode}</p>
                            </button>
                        </div>
                    </div>
                    <span className='ReferralCode__tooltip'>
                        <span className='tooltiptext'>{copySuccess}</span>
                    </span>
                </div>
                <div className='ReferralCode__data'>
                    <div className='ReferralCode__data-title'>
                        <p>Code Uses ({referralUserNumber})</p>
                    </div>
                    {referralUsers.map((user, index) => (
                        user.register_type === 'silent_login'
                            ? <div className='d-flex ReferralCode__data-users'>
                                <div className='ReferralCode__data-users-image'>
                                    <span className='ReferralCode__data-users-image-empty'/>
                                </div>
                                <div className='d-flex ReferralCode__data-users-details'>
                                    <div className='ReferralCode__data-users-details-name'>
                                        <p className='ReferralCode__data-users-details-name-empty'>Not Logged Yet</p>
                                    </div>
                                    <div className='ReferralCode__data-users-details-date'>
                                        <p>{format(new Date(user.created_at), 'dd/MMM/yyyy')}</p>
                                    </div>
                                </div>
                            </div>
                            : <div className='d-flex ReferralCode__data-users'>
                                <div className='ReferralCode__data-users-image'>
                                    {!user.profile.profile_picture
                                        ? <span className='ReferralCode__data-users-image-empty'/>
                                        : <img className='ReferralCode__data-users-image' alt='User Profile' src={user.profile.profile_picture}/>}
                                </div>
                                <div className='d-flex ReferralCode__data-users-details'>
                                    <div className='ReferralCode__data-users-details-name'>
                                        <p>{user.profile.name}</p>
                                    </div>
                                    <div className='ReferralCode__data-users-details-date'>
                                        <p>{format(new Date(user.created_at), 'dd/MMM/yyyy')}</p>
                                    </div>
                                </div>
                            </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
