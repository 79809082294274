import { ELOQUENT } from '../config/constants';

/**
 * Get user preference
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function getUserPreference(cancelKey) {
    return ELOQUENT.NotificationPreference.getAll(cancelKey);
}

/**
 * Update user preference
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function updateUserPreference(data, cancelKey) {
    return ELOQUENT.NotificationPreference.modify(data, cancelKey);
}

/**
 * Get user notification
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function getUserNotification(cancelKey) {
    return ELOQUENT.Notification.getAll(cancelKey);
}

/**
 * Get unread user notification
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function getUnreadUserNotification(data, cancelKey) {
    return ELOQUENT.Notification.getUnread(data, cancelKey);
}

/**
 * Update read mark user notification
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function updateReadUserNotification(data, cancelKey) {
    return ELOQUENT.Notification.setRead(data, cancelKey);
}

/**
 * Delete user notification
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function deleteUserNotification(data, cancelKey) {
    return ELOQUENT.Notification.deleteNotifications(data, cancelKey);
}

/**
 * update FCM token
 * @param {String} userId
 * @param {String} token
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function updateFcmToken(userId, token, cancelKey) {
    return ELOQUENT.User.updateFCM(userId, {
        device_token: token
    }, cancelKey);
}
