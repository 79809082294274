import React from 'react';
import PropTypes from 'prop-types';
import Button from 'creatella-react-components/lib/Button';
import Modal from "components/Modal/Modal";

NewCompanyModal.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onClick: PropTypes.func
};

export default function NewCompanyModal({ isModalVisible, toggleModal, onClick }) {
    return (
        <Modal
            className='Support__modal'
            customSize={true}
            isOpen={isModalVisible}
            onClose={toggleModal}>
            <h2 className='Support__modal-title'>New company</h2>
            <p className='Support__modal-subtitle'>You are about to create a new company</p>
            <div className='d-flex flex-jsb'>
                <Button label='Cancel' className='Social__modal-cancel-btn' onClick={toggleModal}/>
                <Button label='Let’s go!' className='Social__modal-go-btn' onClick={onClick}/>
            </div>
        </Modal>
    );
}
