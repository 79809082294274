import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faCheckSquare, faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'creatella-react-components/lib/Button';
import COMPANY from 'assets/icons/dashboard-company.svg';
import CLASSES from 'assets/icons/dashboard-classes.svg';
import ON_DEMAND from 'assets/icons/dashboard-ondemand.svg';
import MONETIZE from 'assets/icons/dashboard-finances.svg';
import CLIENTS from 'assets/icons/dashboard-clients.svg';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import Modal from '../../Modal/Modal';
import './AdminModal.scss';
import Card from 'components/Card/Card';

const dataPermission = [
    {
        name: 'Company',
        type: 'company',
        permissions: [
            {
                title: 'All Rights',
                name: 'all_right_company'
            },
            {
                title: 'Manage company description',
                subtitle: 'Modify the public image of the company',
                name: 'company_description'
            },
            {
                title: 'Manage company coaches',
                subtitle: 'Invite, remove coaches',
                name: 'company_coaches'
            }
        ]
    },
    {
        name: 'Classes',
        type: 'classes',
        permissions: [
            {
                title: 'All Rights',
                name: 'all_right_company_classes'
            },
            {
                title: 'Manage company classes',
                subtitle: 'Create, edit, remove events, for all staff members',
                name: 'company_classes'
            }
        ]
    },
    {
        name: 'On-demand',
        type: 'demand',
        permissions: [
            {
                title: 'All Rights',
                name: 'all_right_company_on_demand'
            },
            {
                title: 'Manage company availabilities',
                subtitle: 'Create and edit staff members free slots and activities',
                name: 'company_on_demand'
            }
        ]
    },
    {
        name: 'Finances',
        type: 'finances',
        permissions: [
            {
                title: 'All Rights',
                name: 'all_right_company_finance'
            },
            {
                title: 'Create new',
                subtitle: 'Create payouts, invoices',
                name: 'company_finance_create'
            },
            {
                title: 'Manage money',
                subtitle: 'Refund a client',
                name: 'company_finance_manage_money'
            },
            {
                title: 'Export data',
                subtitle: 'Export function of selected data',
                name: 'company_finance_export_data'
            }
        ]
    },
    {
        name: 'Clients',
        type: 'clients',
        permissions: [
            {
                title: 'All Rights',
                name: 'all_right_company_clients'
            },
            {
                title: 'Manage company clients',
                subtitle: 'Import, invite and remove clients, create new groups, consult statistics',
                name: 'company_clients'
            }
        ]
    }
];

export default class AdminModal extends Component {
    static propTypes = {
        companyName: PropTypes.string,
        data: PropTypes.object,
        isModalAdminVisible: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool,
        toggleAdminModal: PropTypes.func.isRequired,
        isRefreshing: PropTypes.bool,
        onSubmitPermissions: PropTypes.func
    }

    state = {
        adminType: null,
        permissionsCheck: {
            all_right_company: false,
            all_right_company_classes: false,
            all_right_company_on_demand: false,
            all_right_company_finance: false,
            all_right_company_clients: false,
            company_description: false,
            company_clients: false,
            company_coaches: false,
            company_classes: false,
            company_on_demand: false,
            company_finance_create: false,
            company_finance_manage_money: false,
            company_finance_export_data: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            if (this.props.data) {
                const {
                    company_description, company_clients, company_coaches,
                    company_classes, company_on_demand, company_finance_create, company_finance_manage_money,
                    company_finance_export_data
                } = this.props.data;

                this.setState({
                    permissionsCheck: {
                        all_right_company: (company_description && company_coaches) ?? false,
                        all_right_company_classes: company_classes ?? false,
                        all_right_company_on_demand: company_on_demand ?? false,
                        all_right_company_finance: (company_finance_create && company_finance_export_data && company_finance_manage_money) ?? false,
                        all_right_company_clients: company_clients ?? false,
                        ...this.props.data
                    }
                });
            }

            if (this.props.isRefreshing) {
                this.setState({ adminType: null });
            }
        }
    }

    setAdmin = (val) => {
        this.setState({
            adminType: val
        });
    };

    setPermissions = (name, type, checked, allpermissions) => {
        const permissions = this.state.permissionsCheck;

        if (type === 'company' && this.state.permissionsCheck.all_right_company) {
            permissions.all_right_company = false;
            this.setState({
                permissionsCheck: permissions
            });
        }

        if (type === 'classes' && this.state.permissionsCheck.all_right_company_classes) {
            permissions.all_right_company_classes = false;

            this.setState({
                permissionsCheck: permissions
            });
        }

        if (type === 'demand' && this.state.permissionsCheck.all_right_company_on_demand) {
            permissions.all_right_company_on_demand = false;

            this.setState({
                permissionsCheck: permissions
            });
        }

        if (type === 'finances' && this.state.permissionsCheck.all_right_company_finance) {
            permissions.all_right_company_finance = false;

            this.setState({
                permissionsCheck: permissions
            });
        }

        if (type === 'clients' && this.state.permissionsCheck.all_right_company_clients) {
            permissions.all_right_company_clients = false;

            this.setState({
                permissionsCheck: permissions
            });
        }

        // check if all permisions should be checked or not
        let checkAll = true;

        for (let index = 0; index < allpermissions.length; index++) {
            const element = allpermissions[index].name;

            if (index > 0 && element !== name && (!permissions[element] || !checked)) {
                checkAll = false;
                break;
            }
        }

        if (checkAll) {
            permissions[allpermissions[0].name] = checked;
        }

        this.setState({
            permissionsCheck: {
                ...permissions,
                [name]: !this.state.permissionsCheck[name]
            }
        });
    }

    setAllRightPermission = (name) => {
        if (name === dataPermission[0].type) {
            this.setState({
                permissionsCheck: {
                    ...this.state.permissionsCheck,
                    all_right_company: !this.state.permissionsCheck.all_right_company
                }
            }, () => {
                this.setState({
                    permissionsCheck: {
                        ...this.state.permissionsCheck,
                        company_description: this.state.permissionsCheck.all_right_company,
                        company_coaches: this.state.permissionsCheck.all_right_company
                    }
                });
            });
        }

        if (name === dataPermission[1].type) {
            this.setState({
                permissionsCheck: {
                    ...this.state.permissionsCheck,
                    all_right_company_classes: !this.state.permissionsCheck.all_right_company_classes
                }
            }, () => {
                this.setState({
                    permissionsCheck: {
                        ...this.state.permissionsCheck,
                        company_classes: this.state.permissionsCheck.all_right_company_classes
                    }
                });
            });
        }

        if (name === dataPermission[2].type) {
            this.setState({
                permissionsCheck: {
                    ...this.state.permissionsCheck,
                    all_right_company_on_demand: !this.state.permissionsCheck.all_right_company_on_demand
                }
            }, () => {
                this.setState({
                    permissionsCheck: {
                        ...this.state.permissionsCheck,
                        company_on_demand: this.state.permissionsCheck.all_right_company_on_demand
                    }
                });
            });
        }

        if (name === dataPermission[3].type) {
            this.setState({
                permissionsCheck: {
                    ...this.state.permissionsCheck,
                    all_right_company_finance: !this.state.permissionsCheck.all_right_company_finance
                }
            }, () => {
                this.setState({
                    permissionsCheck: {
                        ...this.state.permissionsCheck,
                        company_finance_create: this.state.permissionsCheck.all_right_company_finance,
                        company_finance_manage_money: this.state.permissionsCheck.all_right_company_finance,
                        company_finance_export_data: this.state.permissionsCheck.all_right_company_finance
                    }
                });
            });
        }

        if (name === dataPermission[4].type) {
            this.setState({
                permissionsCheck: {
                    ...this.state.permissionsCheck,
                    all_right_company_clients: !this.state.permissionsCheck.all_right_company_clients
                }
            }, () => {
                this.setState({
                    permissionsCheck: {
                        ...this.state.permissionsCheck,
                        company_clients: this.state.permissionsCheck.all_right_company_clients
                    }
                });
            });
        }
    }

    handleSubmitPermission = () => {
        this.props.onSubmitPermissions(this.state.permissionsCheck);
        this.props.toggleAdminModal();
    }

    onCloseAdminModal = () => {
        this.props.toggleAdminModal();
        this.setState({
            adminType: null
        });
    }

    renderContentPermissions = (type) => {
        const { isDisabled } = this.props;
        const { permissionsCheck } = this.state;
        const { permissions } = dataPermission.find(item => item.type === type);

        return (
            <div className='d-flex flex-ac flex-jc p-30'>
                <div>
                    { permissions.map((item, i) => (
                        <div className='d-flex' key={i}>
                            { permissionsCheck[item.name] ? (
                                <FontAwesomeIcon icon={faCheckSquare} color='#006eb8' size={'lg'}
                                    className='Blue-Header__back'
                                    onClick={i === 0 ? () => this.setAllRightPermission(type) : () => this.setPermissions(item.name, type, false, permissions)}/>
                            ) : (
                                <FontAwesomeIcon icon={faSquare} color={isDisabled ? '#ededee' : '#58595B'} size={'lg'}
                                    className='Blue-Header__back'
                                    onClick={!isDisabled ? i === 0 ? () => this.setAllRightPermission(type) : () => this.setPermissions(item.name, type, true, permissions) : null}/>
                            )}
                            <div>
                                <p className='Support__modal-admin-text'>{item.title}</p>
                                <p className='Support__modal-admin-subtext' style={{ fontWeight: 400 }}>{item.subtitle}</p>
                            </div>
                        </div>
                    )) }
                </div>
            </div>
        );
    }

    render() {
        const { isModalAdminVisible, toggleAdminModal } = this.props;
        const { adminType, permissionsCheck } = this.state;
        const {
            company_description, company_coach,
            company_classes, company_clients, company_on_demand, company_finance_create,
            company_finance_manage_money, company_finance_export_data
        } = permissionsCheck;

        return (
            <Modal
                className='Support__modal AdminModal'
                isOpen={isModalAdminVisible}
                onClose={this.onCloseAdminModal}>
                {adminType && <FontAwesomeIcon icon={faChevronLeft} color='#000' size={'lg'}
                    className='Blue-Header__back-admin' onClick={() => this.setState({ adminType: null })} /> }
                <h2 className='Support__modal-title'>
                    {adminType === 'company' ? 'Company' : adminType === 'classes' ? 'Classes' : adminType === 'demand' ? 'On-demand' : adminType === 'finances' ? 'Finances' : 'Admin rights'}</h2>
                <p className='Support__modal-subtitle2'>{adminType === null ? 'Select the app you want to see' : 'Which rights would you like to share?'}</p>
                <FontAwesomeIcon icon={faTimes} color='#000' size={'lg'}
                    className='Blue-Header__close-admin' onClick={this.onCloseAdminModal} />

                <div className='Support__modal-border'> </div>

                {adminType === null
                    ? <div className='d-flex flex-jsa'>
                        <Card
                            highlighted={company_description || company_coach}
                            image={COMPANY}
                            label={'COMPANY'}
                            onClick={() => this.setAdmin('company')}/>
                        <Card
                            highlighted={company_classes}
                            image={CLASSES}
                            label={'CLASSES'}
                            onClick={() => this.setAdmin('classes')}/>
                        <Card
                            highlighted={company_on_demand}
                            image={ON_DEMAND}
                            label={'ON-DEMAND'}
                            onClick={() => this.setAdmin('demand')}/>
                        <Card
                            highlighted={company_finance_manage_money || company_finance_export_data || company_finance_create}
                            image={MONETIZE}
                            label={'FINANCES'}
                            onClick={() => this.setAdmin('finances')}/>
                        <Card
                            highlighted={company_clients}
                            image={CLIENTS}
                            label={'CLIENTS'}
                            onClick={() => this.setAdmin('clients')}/>
                    </div>
                    : <div>
                        { this.renderContentPermissions(adminType) }
                        <div className='d-flex flex-jsb'>
                            <Button label='Cancel' className='Social__modal-cancel-btn' onClick={toggleAdminModal}/>
                            <Button label='Ok' className='Social__modal-ok-btn' onClick={() => this.handleSubmitPermission()}/>
                        </div>
                    </div>
                }
            </Modal>
        );
    }
}
