import { ELOQUENT } from 'config/constants';

/**
 * get all companies for a user
 * @param {String} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function fetchCompanyCoaches(id, cancelKey) {
    return ELOQUENT.Company.getCoaches(id, cancelKey);
}

export function createPossibleActivitiesCoach(coachId, data, cancelKey) {
    return ELOQUENT.Coach.createPossibleActivities(coachId, data, cancelKey);
}

export function getPossibleActivitiesCoach(coachId, cancelKey) {
    return ELOQUENT.Coach.getPossibleActivities(coachId, cancelKey);
}

export function createPossibleAddress(coachId, data, cancelKey) {
    return ELOQUENT.Coach.createPossibleAddress(coachId, data, cancelKey);
}

export function getPossibleAddress(coachId, cancelKey) {
    return ELOQUENT.Coach.getPossibleAddress(coachId, cancelKey);
}

export function createSavedItemCoach(data) {
    return ELOQUENT.Coach.createSavedItem(data);
}

export function getSavedItemsCoach(id) {
    return ELOQUENT.Coach.getSavedItems(id);
}

export function getReferredUser(id) {
    return ELOQUENT.Coach.getReferredUsers(id);
}
