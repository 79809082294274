import { ELOQUENT } from 'config/constants';
import { getFcmToken } from 'redux/reducers/utils/alerts/helpers';

/**
 * Get logged in user's profile data
 * @returns {Promise}
 */
export function getAuthedProfile() {
    return ELOQUENT.User.me({
        fcm_token: getFcmToken()
    });
}

/**
 * Update a user's profile data
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateUserProfile(id, {
    first_name,
    last_name,
    email,
    gender,
    spoken_language,
    residence,
    timezone,
    profile_picture,
    whatsapp,
    postal_code
}) {
    return ELOQUENT.User.updateProfile(id, {
        first_name,
        last_name,
        email,
        gender,
        spoken_language,
        residence,
        timezone,
        profile_picture,
        whatsapp,
        postal_code
    });
}

/**
 * get all clients
 * @param {Object} data
 * @param {Object} cancelToken
 * @returns {Promise}
 */
export function getAllClients({ page, search, sortOrder, sortBy }, cancelToken) {
    return ELOQUENT.User.getAll({
        clients: true,
        page,
        name: search,
        attribute: sortBy,
        sortby: sortOrder
    }, cancelToken);
}

/**
 * get all coaches
 * @param {Object} cancelToken
 * @returns {Promise}
 */
export function getAllCoaches({search}, cancelToken) {
    return ELOQUENT.Coach.getAllCoaches({name: search}, cancelToken);
    // return ELOQUENT.User.getAll({ coaches: true }, cancelToken);
}

/**
 * Add social media
 * @param {Object} data
 * @returns {Promise}
 */
export function addSocialMedia(data) {
    return ELOQUENT.User.addSocialMedia(data);
}

/**
 * Link social media
 * @param {Object} data
 * @returns {Promise}
 */
export function linkSocialMedia(data) {
    return ELOQUENT.User.linkSocialMedia(data);
}

/**
 * Delete account
 * @param {String} id
 * @returns {Promise}
 */
export function deleteUser(id) {
    return ELOQUENT.User.deleteOne(id);
}

/**
 * Delete account
 * @param {String} provider
 * @returns {Promise}
 */
export function getSocialContacts(provider) {
    return ELOQUENT.User.getSocialContacts({ provider });
}

/**
 * Delete account
 * @param {String} provider
 * @returns {Promise}
 */
export function unLinkSocialMedia(provider) {
    return ELOQUENT.User.unLinkSocialMedia({ provider });
}

/**
 * Delete account
 * @param {Object} data
 * @returns {Promise}
 */
export function postSocialMedia(data) {
    return ELOQUENT.User.PostSocialMedia({ ...data });
}

