import { ELOQUENT, CALENDAR_RECURRENCE_TYPES } from 'config/constants';

export const LIVE_CLASS_TYPE = 'live';

export const CLASS_RECURRENCE_MAP = {
    once: CALENDAR_RECURRENCE_TYPES.ONCE,
    daily: CALENDAR_RECURRENCE_TYPES.DAILY,
    weekly: CALENDAR_RECURRENCE_TYPES.WEEKLY,
    monthly: CALENDAR_RECURRENCE_TYPES.MONTHLY
};

/**
 * get all classes for a company
 * @param {Object} id
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function fetchCompanyClasses(id, { coachId } = {}, cancelKey) {
    return ELOQUENT.Company.getClasses(id, {
        coach_id: coachId
    }, cancelKey);
}

/**
 * fetch classes
 * @param {id} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function fetchClasses(id, cancelKey) {
    return ELOQUENT.Company.getClasses(id, cancelKey);
}

/**
 * fetch one class
 * @param {id} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function fetchOneClass(id, cancelKey) {
    return ELOQUENT.Class.getOne(id, cancelKey);
}

/**
 * create classes
 * @param {id} id
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function createClass(id, data, cancelKey) {
    return ELOQUENT.Company.createClass(id, {
        ...data,
        clients: undefined,
        price: data.price.map(price => ({
            discount: price.discount,
            duration: price.duration,
            is_selected: price.is_selected,
            in_app_product_id: price.id
        })),
        time_to_go: data.time_to_go ? `${data.time_to_go}:00` : '',
        time_to_return: data.time_to_return ? `${data.time_to_return}:00` : ''
    }, cancelKey);
}

/**
 * delete class
 * @param {id} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function deleteOneClass(id, cancelKey) {
    return ELOQUENT.Class.deleteOne(id, cancelKey);
}

/**
 * publish class
 * @param {id} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function publishClass(id, cancelKey) {
    return ELOQUENT.Class.publish(id, cancelKey);
}

/**
 * unpublish class
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function unpublishClass({ id, message }, cancelKey) {
    return ELOQUENT.Class.unPublish(id, { message }, cancelKey);
}

/**
 * update a company
 * @param {String} classId
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function updateClass(classId, data, cancelKey) {
    return ELOQUENT.Class.updateOne(classId, {
        ...data,
        price: data.price.map(price => ({
            discount: price.discount,
            duration: price.duration,
            is_selected: price.is_selected,
            in_app_product_id: price.id
        })),
        time_to_go: data.time_to_go ? `${data.time_to_go}:00` : '',
        time_to_return: data.time_to_return ? `${data.time_to_return}:00` : ''
    }, cancelKey);
}

/**
 * update multiple classes at once
 * @param {Array} events
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function bulkUpdateClass(events, cancelKey) {
    return ELOQUENT.Class.bulkUpdate({
        event: events
    }, cancelKey);
}

/**
 * Create zoom link
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function createZoomLink(data, cancelKey) {
    return ELOQUENT.Zoom.createMeeting(data);
}

/**
 * Create zoom link
 * @param {String} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */

export function shareClass(id, cancelKey) {
    return ELOQUENT.Class.share(id);
}

/**
 * Create zoom link
 * @param {String} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */

export function getShareRedirectUrl(id, cancelKey) {
    return ELOQUENT.Class.webhookEvent(id);
}
