import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faThLarge, faUserTie, faTimes } from '@fortawesome/free-solid-svg-icons';
import SafeImage from 'components/SafeImage/SafeImage';
import SideNav from './components/Side/AppHeaderSide';
import Button, { THEMES as BUTTON_THEMES } from 'components/Button/AppButton';
import { ROUTES } from 'config/constants';
import { APPS } from 'config/apps';
import LOGO from 'assets/logos/main.png';
import ARROW from 'assets/icons/curved-right-arrow.png';
import './AppHeaderNav.scss';

const listeners = {
    setPublihAllVisibility: null,
    onPublishClick: null,
    onDropDownClick: null,
    setPublishLoading: null,
    isCompanyStatus: false
};

let headerButtonContent = null;

export default class AppHeaderNav extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        isSub: PropTypes.bool,
        isFetching: PropTypes.bool,
        companies: PropTypes.array,
        selectedCompanyId: PropTypes.string,
        history: PropTypes.object,
        account: PropTypes.object.isRequired,
        preferences: PropTypes.object.isRequired,
        notifications: PropTypes.object.isRequired,
        onItemSelect: PropTypes.func.isRequired,
        onUpdatePreference: PropTypes.func.isRequired,
        onReadNotification: PropTypes.func.isRequired,
        onDeleteNotification: PropTypes.func.isRequired,
        onLogout: PropTypes.func.isRequired,
        onDeleteCompany: PropTypes.func.isRequired,
        onUpdateProfile: PropTypes.func.isRequired,
        onDeleteAccount: PropTypes.func.isRequired,
        onPublishCompany: PropTypes.func.isRequired,
        onUnpublishCompany: PropTypes.func.isRequired,
        onQuitCompany: PropTypes.func.isRequired,
        onGrantPermissions: PropTypes.func.isRequired,
        onSendClientsInvite: PropTypes.func.isRequired,
        isSubmitLoading: PropTypes.object,
        formSuccess: PropTypes.object,
        formError: PropTypes.object,
        showHint: PropTypes.bool.isRequired,
        setShowHint: PropTypes.func.isRequired
    }

    ALL_APPS = Object.keys(APPS).map(key => APPS[key]);

    constructor(props) {
        super(props);
        this.menuNode = null;
        this.appsNavNode = null;
        this.state = {
            isMenuOpen: false,
            showSide: false,
            showAppsNav: false,
            showPublishButton: false,
            showPublishDropdown: false,
            isPublishLoading: null
        };
    }

    componentDidMount() {
        window.addEventListener('click', this.handleWindowClick);

        listeners.setPublihAllVisibility = (isButtonVisible, isDropdownVisible) => {
            this.setState({
                showPublishButton: isButtonVisible,
                showPublishDropdown: isDropdownVisible
            });
        };

        listeners.setPublishLoading = isLoading => {
            this.setState({ isPublishLoading: isLoading });
        };
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleWindowClick);
    }

    handleWindowClick = e => {
        const { target } = e;
        const menuNode = this.menuNode;
        const appsNavNode = this.appsNavNode;

        if (menuNode && !menuNode.contains(target)) {
            this.setState({
                isMenuOpen: false
            });
        }

        if (appsNavNode && !appsNavNode.contains(target)) {
            this.setState({
                showAppsNav: false
            });
        }
    }

    handleMenuToggle = e => {
        const { isMenuOpen } = this.state;

        this.stopPropagation(e);

        this.setState({
            isMenuOpen: !isMenuOpen
        });
    }

    handleAppsNavToggle = e => {
        const { showAppsNav } = this.state;

        this.stopPropagation(e);

        this.setState({
            showAppsNav: !showAppsNav
        });
    }

    handleItemSelect = id => e => {
        const { onItemSelect } = this.props;

        onItemSelect && onItemSelect(id);

        this.setState({
            isMenuOpen: false
        });
    }

    handleToggleSide = () => this.setState({
        showSide: !this.state.showSide
    })

    getSelectedCompany = () => {
        const { companies, selectedCompanyId } = this.props;

        return companies.find(
            company => company.id === selectedCompanyId
        );
    }

    handlePublishClick = () => listeners.onPublishClick && listeners.onPublishClick();
    handleDropdownClick = () => listeners.onDropDownClick && listeners.onDropDownClick();

    handleHintButtonClick = () => this.props.setShowHint(false)

    stopPropagation = e => e.stopPropagation();

    render() {
        const { isMenuOpen, showSide, showAppsNav, showPublishButton, showPublishDropdown, isPublishLoading } = this.state;
        const {
            className,
            isFetching,
            companies,
            onItemSelect,
            selectedCompanyId,
            title,
            isSub,
            showHint,

            history,
            account,
            preferences,
            notifications,
            onUpdatePreference,
            onLogout,
            onDeleteCompany,
            onDeleteAccount,
            onUpdateProfile,
            onPublishCompany,
            onUnpublishCompany,
            onQuitCompany,
            onGrantPermissions,
            onSendClientsInvite,
            isSubmitLoading,
            formSuccess,
            formError,
            onReadNotification,
            onDeleteNotification,
            ...others
        } = this.props;

        const companiesLength = companies.length;
        const renderMenu = companiesLength && companiesLength > 1;
        const showCompany = !!companiesLength;

        const selectedCompany = this.getSelectedCompany();
        const installedAppIds = selectedCompany ? selectedCompany.installedAppIds : [];
        const userPhoto = selectedCompany?.coach_profile?.image;
        const selectedApp = this.ALL_APPS.find(item => item.link === history.location.pathname) || {};
        const totalUnreadNotifications = notifications ? notifications.filter(item => !item?.pivot?.is_read).length : 0;

        return (
            <div
                {...others}
                className={`
                    AppHeaderNav
                    ${isMenuOpen ? 'AppHeaderNav--menu-open' : ''}
                    ${!renderMenu ? 'AppHeaderNav--no-drop' : ''}
                    ${!showCompany ? 'AppHeaderNav--no-company' : ''}
                    ${showPublishButton ? 'AppHeaderNav--isPublish' : ''}
                    ${isSub ? 'AppHeaderNav--isSub' : ''}
                    ${className}
                `}>
                {showPublishButton ? (
                    <div className='AppHeaderNav__publish'>
                        {showPublishDropdown && (
                            <FontAwesomeIcon
                                className='AppHeaderNav__publish-dropdown'
                                onClick={this.handleDropdownClick}
                                icon={faChevronDown} />
                        )}
                        <Button
                            className='AppHeaderNav__publish-button'
                            theme={BUTTON_THEMES.DARK_BLUE}
                            noShrink={true}
                            round={true}
                            loading={isPublishLoading}
                            onClick={this.handlePublishClick}>
                            <img
                                className='AppHeaderNav__publish-button-icon'
                                src={ARROW} />
                            {headerButtonContent || (listeners.isCompanyStatus ? 'Publish' : 'Save')}
                        </Button>
                    </div>
                ) : (
                    <>
                        {showHint && (
                            <div className='AppHeaderNav__overlay' />
                        )}
                        {isSub ? (
                            <div className='AppHeaderNav__title'>
                                <Link
                                    className='AppHeaderNav__title-back'
                                    to={ROUTES.DASHBOARD()}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </Link>
                                <div className='AppHeaderNav__title-apps'>
                                    <FontAwesomeIcon
                                        className='AppHeaderNav__title-apps-icon'
                                        icon={faThLarge}
                                        onClick={this.handleAppsNavToggle} />
                                    {showAppsNav && (
                                        <div
                                            ref={node => (this.appsNavNode = node)}
                                            className='AppHeaderNav__title-apps-drop'>
                                            <div className='AppHeaderNav__title-apps-drop-header'>
                                                <span className='AppHeaderNav__title-apps-drop-header-name'>
                                                    <FontAwesomeIcon
                                                        className='AppHeaderNav__title-apps-drop-header-name-icon'
                                                        icon={faThLarge} />
                                                    {title}
                                                </span>
                                                <FontAwesomeIcon
                                                    className='AppHeaderNav__title-apps-drop-header-close'
                                                    icon={faTimes}
                                                    onClick={this.handleAppsNavToggle} />
                                            </div>
                                            <div className='AppHeaderNav__title-apps-drop-list'>

                                                {this.ALL_APPS.filter(
                                                    app => (installedAppIds.includes(app.id))
                                                ).map(app => (
                                                    <Link
                                                        key={app.id}
                                                        className={`
                                                            AppHeaderNav__title-apps-drop-list-item
                                                            ${selectedApp.id === app.id ? 'AppHeaderNav__title-apps-drop-list-item--isActive' : ''}
                                                        `}
                                                        to={app.link}
                                                        onClick={this.handleAppsNavToggle}>
                                                        <img
                                                            className='AppHeaderNav__title-apps-drop-list-item-image'
                                                            src={app.icon}
                                                            alt={app.name} />
                                                    </Link>
                                                ))
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {title}
                            </div>
                        ) : (
                            <div className='AppHeaderNav__company'>
                                <div
                                    className='AppHeaderNav__company-main'
                                    onClick={renderMenu ? this.handleMenuToggle : undefined}>
                                    {showHint && (
                                        <div
                                            className='AppHeaderNav__company-main-hint'
                                            onClick={this.stopPropagation}>
                                            <div className='AppHeaderNav__company-main-hint-text'>
                                                <b>{selectedCompany.name}</b> has been included in your companies.
                                                <br/>
                                            Select to start working.
                                            </div>
                                            <Button
                                                className='AppHeaderNav__company-main-hint-button'
                                                theme={BUTTON_THEMES.DARK_BLUE}
                                                onClick={this.handleHintButtonClick}>
                                            Got it
                                            </Button>
                                        </div>
                                    )}
                                    <SafeImage
                                        className='AppHeaderNav__company-main-logo'
                                        src={showCompany ? selectedCompany.logo : LOGO}
                                        alt={showCompany ? selectedCompany.name : 'logo'}
                                        isSquareRound={true}
                                        size={48} />
                                    {showCompany && (
                                        <>
                                            <span className='AppHeaderNav__company-main-name'>
                                                {selectedCompany.name}
                                            </span>
                                            {renderMenu && (
                                                <span className='AppHeaderNav__company-main-drop'>
                                                    <FontAwesomeIcon
                                                        className='AppHeaderNav__company-main-drop-icon'
                                                        icon={faChevronDown} />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </div>

                                {isMenuOpen && (
                                    <div
                                        ref={node => (this.menuNode = node)}
                                        className='AppHeaderNav__company-menu'>
                                        {companies.map(company => (
                                            <div
                                                key={company.id}
                                                className='AppHeaderNav__company-menu-item'
                                                onClick={this.handleItemSelect(company.id)}>
                                                <SafeImage
                                                    className='AppHeaderNav__company-menu-item-image'
                                                    src={company.logo}
                                                    alt={company.name}
                                                    isSquareRound={true}
                                                    size={30} />
                                                <span className='AppHeaderNav__company-menu-item-text'>
                                                    {company.name}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        {totalUnreadNotifications > 0 && (
                            <span className='AppHeaderNav__company-main-notif'>
                                {totalUnreadNotifications}
                            </span>
                        )}
                        <SafeImage
                            className='AppHeaderNav__photo'
                            src={userPhoto}
                            alt='user photo'
                            icon={faUserTie}
                            isRound={true}
                            onClick={this.handleToggleSide}/>
                        <SideNav
                            show={showSide}
                            onClose={this.handleToggleSide}
                            history={history}
                            isFetching={isFetching}
                            companies={companies}
                            account={account}
                            preferences={preferences}
                            notifications={notifications}
                            totalUnreadNotifications={totalUnreadNotifications}
                            selectedCompany={selectedCompany}
                            onItemSelect={onItemSelect}
                            onUpdatePreference={onUpdatePreference}
                            onReadNotification={onReadNotification}
                            onDeleteNotification={onDeleteNotification}
                            onLogout={onLogout}
                            onDeleteCompany={onDeleteCompany}
                            onDeleteAccount={onDeleteAccount}
                            onUpdateProfile={onUpdateProfile}
                            onPublishCompany={onPublishCompany}
                            onUnpublishCompany={onUnpublishCompany}
                            onQuitCompany={onQuitCompany}
                            onGrantPermissions={onGrantPermissions}
                            onSendClientsInvite={onSendClientsInvite}
                            isSubmitLoading={isSubmitLoading}
                            formSuccess={formSuccess}
                            formError={formError}/>
                    </>
                )}
            </div>
        );
    }
}

export function showHeaderPublishButton(onPublishClick, onDropDownClick, content) {
    listeners.onPublishClick = onPublishClick;
    listeners.onDropDownClick = onDropDownClick;
    headerButtonContent = content;
    listeners.setPublihAllVisibility && listeners.setPublihAllVisibility(true, !!onDropDownClick);
}

export function hideHeaderPublishButton() {
    listeners.onPublishClick = null;
    listeners.onDropDownClick = null;
    listeners.setPublihAllVisibility && listeners.setPublihAllVisibility(false, false);
    listeners.setPublishLoading && listeners.setPublishLoading(null);
}

export function setHeaderPublishButtonLoading(isLoading) {
    listeners.setPublishLoading(isLoading);
}

export function setHeaderPublishButtonCompanyStatus(isActive) {
    listeners.isCompanyStatus = isActive;
}
