import { ELOQUENT } from 'config/constants';
import { format } from 'date-fns';
import { formatSinceDate } from '../utils/utils';


/**
* get all companies for a user
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function getAllCompanies(cancelKey) {
    return ELOQUENT.Company.getAll(cancelKey);
}

/**
 * Get one company
 * @param {String} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function getOneCompany(id, cancelKey) {
    return ELOQUENT.Company.getOneCompany(id, { with_balance: true }, cancelKey);
}

/**
 * Get one company
 * @param {String} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function getCompanyCoaches(id, cancelKey) {
    return ELOQUENT.Company.getCoaches(id, cancelKey);
}

/**
 * create a company
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function createCompany(
    { name, since, city, country, description, keywords, logo, banner, coaches, clients },
    cancelKey
) {
    return ELOQUENT.Company.createOne({
        name,
        since: format(since, 'yyyy-MM-dd\'T\'HH:mm:ss'),
        owner_city: city,
        owner_country: country,
        owner_image: banner || null,
        about: description,
        keywords,
        logo: logo || undefined,
        banner: banner || null,
        coach: coaches ? coaches.email : undefined,
        client: clients ? [
            {
                whatsapp: clients.phone
            },
            {
                email: clients.email
            },
            {
                id: clients.id
            }
        ] : undefined
    }, cancelKey);
}

/**
 * delete a company
 * @param {String} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function deleteCompany(id, cancelKey) {
    return ELOQUENT.Company.deleteOne(id, cancelKey);
}

/**
 * update a company
 * @param {String} companyId
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function updateCompany(
    companyId,
    { name, since, city, country, about, keywords, logo, banner, coach, client, phone_number, address, postal_code, website, lat, lng },
    cancelKey
) {
    const data = {
        name,
        since: since ? format(new Date(since), 'yyyy-MM-dd\'T\'HH:mm:ss') : undefined,
        owner_city: city,
        owner_country: country,
        about,
        keywords,
        banner,
        phone_number,
        address,
        postal_code,
        lat,
        lng,
        website
    };

    if (logo) data.logo = logo;

    return ELOQUENT.Company.updateOne(companyId, data, cancelKey);
}

/**
 * update company coach profile
 * @param {String} companyId
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function updateCoach(companyId, data, cancelKey) {
    const {
        coach_id,
        first_name,
        last_name,
        about,
        email,
        nationality,
        residence,
        profile_picture,
        city,
        country,
        address,
        postal_code,
        phone_number,
        lat,
        lng,
        since
    } = data;

    return ELOQUENT.Company.updateCoachProfile(companyId, {
        coach_id,
        first_name,
        last_name,
        about,
        address,
        email,
        phone_number,
        postal_code,
        nationality,
        residence,
        profile_picture,
        photo_url: profile_picture,
        city,
        country,
        lat,
        lng,
        since: formatSinceDate(since)
    }, cancelKey);
}

export function updateCoachAddress(companyId, coachAddress, cancelKey) {
    return ELOQUENT.Company.updateCoachAddress(companyId, coachAddress, cancelKey);
}

export function updateCompanyAddress(companyId, address, cancelKey) {
    return ELOQUENT.Company.updateCompanyAddress(companyId, address, cancelKey);
}

/**
 * Publish a company
 * @param {String} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function publishCompany(id, cancelKey) {
    return ELOQUENT.Company.publish(id, cancelKey);
}

/**
 * Unpublish a company
 * @param {String} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function unpublishCompany(id, cancelKey) {
    return ELOQUENT.Company.unpublish(id, cancelKey);
}

/**
 * Quit from a company
 * @param {String} id
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function quitFromCompany(id, cancelKey) {
    return ELOQUENT.Company.quit(id, cancelKey);
}

/**
 * Grant permissions to coach
 * @param {String} id
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function grantPermissions(id, {
    user_id,
    company_description,
    company_coaches,
    company_clients,
    company_client_to_coach,
    company_classes,
    company_on_demand,
    company_finance_create,
    company_finance_manage_money,
    company_finance_export_data
}, cancelKey) {
    return ELOQUENT.Company.grantPermissions(id, {
        user_id,
        company_description,
        company_coaches,
        company_clients,
        company_client_to_coach,
        company_classes,
        company_on_demand,
        company_finance_create,
        company_finance_manage_money,
        company_finance_export_data
    }, cancelKey);
}

/**
 * update profile of company owner
 * @param {String} companyId
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function updateCompanyOwnerProfile(companyId, data, cancelKey) {
    // console.log(user_id,
    //     first_name,
    //     last_name,
    //     email,
    //     about,
    //     nationality,
    //     spoken_language,
    //     timezone,
    //     profile_picture,
    //     city,
    //     country,
    //     address,
    //     postal_code);
    return ELOQUENT.Company.updateOwnerProfile(companyId, data, cancelKey);
}

/**
 * update profile of company owner
 * @param {String} companyId
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function companyDeleteCoach(companyId, data, cancelKey) {
    return ELOQUENT.Company.deleteCoach(companyId, data, cancelKey);
}
