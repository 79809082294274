/* eslint-disable react/jsx-max-props-per-line */

import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import AppLoading from '../Loading/AppLoading';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'config/constants';
import { AuthedRoute, UnauthedRoute, ExternalRedirect } from './components/RouteWrappers';
import {
    LoginContainer, SignupContainer, CompanyCreationContainer, CreateProfileContainer, DashboardContainer,
    CompanyContainer,
    ClassesContainer,
    TestingContainer,
    ClientsContainer,
    PageNotFoundContainer,
    OnDemandContainer,
    FinancesContainer,
    ShareContainer,
    CoachCreationContainer
} from './config/routes';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

// TODO_REPLACE(
//  Route: accessible for anyone
//  UnauthedRoute: accessible for unauthed clients only
//  AuthedRoute: accessible for authed clients only
// )

export default class AppRouter extends Component {
    static propTypes = {
        isAuthed: PropTypes.bool.isRequired,
        hasCompany: PropTypes.bool.isRequired
    }

    shouldComponentUpdate(nextProps) {
        const { isAuthed, hasCompany } = this.props;

        if (isAuthed !== nextProps.isAuthed || hasCompany !== nextProps.hasCompany) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed, hasCompany } = this.props;

        const routeProps = {
            hasCompany,
            isAuthed
        };

        return (
            <Suspense fallback={<AppLoading />}>
                <Switch>
                    <UnauthedRoute
                        {...routeProps}
                        path={ROUTES.LOGIN}
                        exact={true}
                        component={LoginContainer}/>
                    <UnauthedRoute
                        {...routeProps}
                        path={ROUTES.SIGNUP}
                        exact={true}
                        component={SignupContainer}/>
                    <AuthedRoute
                        {...routeProps}
                        path={ROUTES.COMPANY_CREATION}
                        exact={true}
                        component={CompanyCreationContainer}/>

                    <AuthedRoute
                        {...routeProps}
                        path={ROUTES.COACH_CREATION}
                        exact={true}
                        component={CoachCreationContainer}/>
                    <Route
                        {...routeProps}
                        path={ROUTES.INVITED_COACH}
                        exact={true}
                        component={CreateProfileContainer}/>
                    <AuthedRoute
                        {...routeProps}
                        path={ROUTES.CREATE_PROFILE}
                        exact={true}
                        component={CreateProfileContainer}
                        requiresCompany={true} />
                    <AuthedRoute
                        {...routeProps}
                        path={ROUTES.DASHBOARD()}
                        exact={true}
                        component={DashboardContainer}
                        requiresCompany={true} />

                    <AuthedRoute
                        {...routeProps}
                        path={ROUTES.CLASSES()}
                        exact={true}
                        component={ClassesContainer}
                        requiresCompany={true}/>
                    <Route
                        exact={true}
                        {...routeProps}
                        path='/auth/linkedin/callback'
                        component={LinkedInPopUp}/>
                    <AuthedRoute
                        {...routeProps}
                        path={ROUTES.CLIENTS}
                        exact={true}
                        component={ClientsContainer}
                        requiresCompany={true}/>
                    <Route
                        {...routeProps}
                        path={ROUTES._TESTING}
                        exact={true}
                        component={TestingContainer}/>

                    <AuthedRoute
                        {...routeProps}
                        path={ROUTES.COMPANY()}
                        exact={true}
                        component={CompanyContainer}
                        requiresCompany={true}/>
                    <AuthedRoute
                        {...routeProps}
                        path={ROUTES.ONDEMAND}
                        exact={true}
                        component={OnDemandContainer}
                        requiresCompany={true}/>
                    <Route
                        {...routeProps}
                        path={ROUTES.FINANCES}
                        exact={true}
                        component={FinancesContainer}
                        requiresCompany={true}/>
                    <Route
                        {...routeProps}
                        path={ROUTES.SHARE}
                        exact={true}
                        component={ShareContainer}/>

                    <ExternalRedirect
                        exact={true}
                        from={ROUTES.ELUA}
                        to={ROUTES.EXTERNAL_ELUA}/>
                    <ExternalRedirect
                        exact={true}
                        from={ROUTES.PRIVACY_POLICY}
                        to={ROUTES.EXTERNAL_PRIVACY_POLICY}/>

                    <Route component={PageNotFoundContainer} />
                </Switch>
            </Suspense>
        );
    }
}
