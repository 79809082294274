import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

export default class NotificationItem extends Component {
    static propTypes = {
        image: PropTypes.string,
        time: PropTypes.string.isRequired,
        isNew: PropTypes.bool,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        action: PropTypes.string,
        info: PropTypes.string,
        notification: PropTypes.string.isRequired,
        onReadNotification: PropTypes.func,
        onDeleteNotification: PropTypes.func
    }

    state = {
        showDelete: false
    }

    setIsShown = (val) => {
        this.setState({
            showDelete: val
        });
    }

    handleClick = () => {

    }

    onMouseEnter = () => {
        this.setIsShown(true);
        this.props.isNew && this.props.onReadNotification();
    }

    generateTime = () => {
        const theDate = this.props.time.replace(/ /g, 'T');
        const date = new Date(theDate);

        return format(date, 'dd MMM');
    }

    render() {
        const { showDelete } = this.state;
        const { image, title, subtitle, time, isNew, info, notification, onDeleteNotification } = this.props;

        return (
            <div className='Notifications__item' onMouseEnter={this.onMouseEnter}
                onMouseLeave={() => this.setIsShown(false)} onClick={this.handleClick}>
                <div className='Notifications__item-header'>
                    <div className='d-flex flex-ac'>

                        <div className='Notifications__item-user'>
                            {image ? (
                                <img className='Notifications__item-user--circle-icon' src={image} alt='user' />
                            ) : (
                                <img className='Notifications__item-user--circle-icon' src='https://cdn1.vectorstock.com/i/1000x1000/02/10/business-woman-avatar-vector-15490210.jpg' alt='user' />
                            )}
                        </div>

                        <p className='Notifications__item-title'>{title}</p>
                    </div>

                    <div className='d-flex flex-ac'>
                        {showDelete
                            ? <FontAwesomeIcon icon={faTimesCircle} color='#58595B' size={'md'}
                                onClick={onDeleteNotification} />
                            : <p className='Notifications__item-hr text-end'>{this.generateTime()}
                                {!!isNew && <span className='Notifications__item-dot'/>}
                            </p>}
                    </div>
                </div>
                <div className='Notifications__item-content'>
                    {/* <p className='Notifications__item-info'>{subtitle}</p> */}
                    <p className='Notifications__item-info-msg'>{info}</p>
                    <p className='Notifications__item-info'>{notification}</p>
                </div>
            </div>
        );
    }
}
