import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { handleSelectedCompanyChange } from 'redux/reducers/company';
import SlideMenu from './SlideMenu/SlideMenu';
import 'react-responsive-modal/styles.css';
import { USER_PERMISSIONS, ROUTES, APP_IDS } from 'config/constants';
import './AppHeaderSide.scss';

class AppHeaderSide extends Component {
    static propTypes = {
        account: PropTypes.object,
        selectedCompany: PropTypes.object,
        isFetching: PropTypes.bool,
        companies: PropTypes.array,
        preferences: PropTypes.object,
        notifications: PropTypes.object,
        totalUnreadNotifications: PropTypes.number,
        onUpdatePreference: PropTypes.func,
        onReadNotification: PropTypes.func,
        onDeleteNotification: PropTypes.func,
        onLogout: PropTypes.func,
        onDeleteCompany: PropTypes.func,
        onDeleteAccount: PropTypes.func,
        onUpdateProfile: PropTypes.func,
        history: PropTypes.object,
        onPublishCompany: PropTypes.func,
        onUnpublishCompany: PropTypes.func,
        onQuitCompany: PropTypes.func,
        onGrantPermissions: PropTypes.func,
        onSendClientsInvite: PropTypes.func,
        onClose: PropTypes.func,
        show: PropTypes.bool,
        isSubmitLoading: PropTypes.object,
        formSuccess: PropTypes.object,
        formError: PropTypes.object,
        onItemSelect: PropTypes.func,
        pushHistory: PropTypes.func.isRequired,
        setSelectedCompany: PropTypes.func.isRequired
    };

    state = {
        showNotifications: false,
        activeTab: 'list',
        showSocial: false,
        showFaq: false,
        showSupport: false,
        showClasses: false,
        showDescription: false,
        showReferralCode: false,
        company: {}
    };

    toggleNotifications = () => {
        this.setState({
            showNotifications: !this.state.showNotifications
        });
    }

    toggleSocial = () => {
        this.setState({
            showSocial: !this.state.showSocial
        });
    }

    toggleFaq = () => {
        this.setState({
            showFaq: !this.state.showFaq
        });
    }

    toggleSupport = () => {
        this.setState({
            showSupport: !this.state.showSupport
        });
    }

    toggleDescription = (company) => {
        const { pushHistory, onClose, setSelectedCompany } = this.props;

        if (company) {
            const { coach_profile: coachProfile, installedAppIds, userPermissions } = company;

            if (coachProfile && installedAppIds.includes(APP_IDS.COMPANY) && userPermissions.includes(USER_PERMISSIONS.COMPANY_COACHES)) {
                setSelectedCompany(company.id);
                pushHistory(ROUTES.COMPANY({ selectedCoachId: coachProfile.id, isCoachSelected: true }));
                onClose();

                return;
            }
        }

        this.setState({
            company: company,
            showDescription: !this.state.showDescription
        });
    }

    toggleReferralCode = (company) => {

        this.setState({
            company: company,
            showReferralCode: !this.state.showReferralCode
        });
    }

    selectTab = (tab) => {
        this.setState({
            activeTab: tab
        });
    }

    handleCloseMenu = () => {
        const { onClose } = this.props;

        onClose();
        this.setState({
            showSocial: false,
            showNotifications: false,
            showFaq: false,
            showSupport: false,
            showClasses: false,
            showDescription: false,
            showReferralCode: false
        });
    }

    render() {
        const { showNotifications, showFaq, showSupport, showDescription, showSocial, activeTab, company } = this.state;
        const {
            show, selectedCompany, onItemSelect, isFetching, companies, history, preferences, notifications, totalUnreadNotifications, account,
            onDeleteNotification, onUpdatePreference, onReadNotification, onDeleteCompany, onDeleteAccount, onUpdateProfile,
            onLogout, onPublishCompany, onUnpublishCompany, onQuitCompany, onGrantPermissions, onSendClientsInvite, formError, formSuccess,
            isSubmitLoading
        } = this.props;

        const coachData = {
            ...account,
            ...(company ? company.coach_profile : {})
        };

        return (
            <div className='Blue-Header'>
                <SlideMenu
                    toggleNotifications={this.toggleNotifications}
                    selectTab={(val) => this.selectTab(val)}
                    showNotifications={showNotifications}
                    activeTab={activeTab}
                    showMenu={show}
                    showFaq={showFaq}
                    toggleFaq={this.toggleFaq}
                    showSupport={showSupport}
                    toggleSupport={this.toggleSupport}
                    showDescription={showDescription}
                    toggleDescription={this.toggleDescription}
                    toggleSocial={this.toggleSocial}
                    showSocial={showSocial}
                    showReferralCode={this.state.showReferralCode}
                    toggleReferralCode={this.toggleReferralCode}
                    closeMenu={this.handleCloseMenu}
                    account={coachData}
                    selectedCompany={selectedCompany}
                    onItemSelect={onItemSelect}
                    isFetching={isFetching}
                    companies={companies}
                    history={history}
                    preferences={preferences}
                    notifications={notifications}
                    totalUnreadNotifications={totalUnreadNotifications}
                    onUpdatePreference={onUpdatePreference}
                    onReadNotification={onReadNotification}
                    onDeleteNotification={onDeleteNotification}
                    onLogout={onLogout}
                    company={company}
                    onDeleteCompany={onDeleteCompany}
                    onDeleteAccount={onDeleteAccount}
                    onUpdateProfile={onUpdateProfile}
                    onPublish={onPublishCompany}
                    onUnpublish={onUnpublishCompany}
                    onQuitCompany={onQuitCompany}
                    onGrantPermissions={onGrantPermissions}
                    onSendClientsInvite={onSendClientsInvite}
                    isSubmitLoading={isSubmitLoading}
                    formSuccess={formSuccess}
                    formError={formError}/>
            </div>
        );
    }
}

const mapDispatchToProps = {
    pushHistory: push,
    setSelectedCompany: handleSelectedCompanyChange
};

export default connect(null, mapDispatchToProps)(AppHeaderSide);
