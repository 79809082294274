import { updateFcmToken } from 'api/notification';
import { setFcmToken, subscribeFcm as subFcm } from './utils/alerts/helpers';

export const ALERT_TYPES = {
    INFO: 'INFO',
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
    ERROR: 'ERROR'
};

export const ALERT_POSITION = {
    TOP_CENTER: 'TOP_CENTER',
    TOP_LEFT: 'TOP_LEFT',
    TOP_RIGHT: 'TOP_RIGHT',
    BOTTOM_CENTER: 'BOTTOM_CENTER',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
    BOTTOM_RIGHT: 'BOTTOM_RIGHT'
};

const ALERTS_PUSH = 'ALERTS_PUSH';
const ALERTS_DISMISS_BY_ID = 'ALERTS_DISMISS_BY_ID';
const ALERTS_DISMISS_ALL = 'ALERTS_DISMISS_ALL';

/**
 * Add alert to stack
 * @param {[Object]} config
 * @param {String} config.icon
 * @param {String} config.icon2
 * @param {Any} config.subText
 * @param {Any} config.text
 * @param {String} config.type
 * @param {String} config.position
 * @param {Func} config.onDismiss
 * @param {Bool} config.isAutoDismiss
 * @param {Number} config.animationDuration
 * @param {Number} config.dismissDuration
 * @param {Func} config.renderTimeDisplay
 * @returns {[Object]}
 */
export function pushAlert(config) {
    return {
        type: ALERTS_PUSH,
        config
    };
}

export function dismissAlert(id) {
    return {
        type: ALERTS_DISMISS_BY_ID,
        id
    };
}

export function dismissAllAlerts() {
    return {
        type: ALERTS_DISMISS_ALL
    };
}

export function subscribeFcm(forceGenration) {
    return async (dispatch, getState) => {
        const { auth: { isAuthed, profile } } = getState();

        if (!isAuthed || !profile.data) {
            return;
        }

        const { id: userId, needs_token: apiForceGenration } = profile.data;

        const token = await subFcm(({ notification }) => {
            // console.log(notification);
            const { title, body, image } = notification;

            dispatch(pushAlert({
                text: title,
                subText: body,
                icon: image,
                type: ALERT_TYPES.SUCCESS
            }));
        }, forceGenration || apiForceGenration);

        if (!token) {
            return;
        }

        await updateFcmToken(userId, token);

        setFcmToken(token);
    };
}

const initialState = {
    alerts: []
};

let INCREMENT_ID = 1;

export default function alerts(state = initialState, action) {
    switch (action.type) {
        case ALERTS_PUSH:
            return {
                ...state,
                alerts: state.alerts.concat({
                    ...action.config,
                    id: INCREMENT_ID++
                })
            };

        case ALERTS_DISMISS_BY_ID: {
            const { id } = action;
            const filterAlertById = (item) => item.id !== id;

            return {
                ...state,
                alerts: state.alerts.filter(filterAlertById)
            };
        }

        case ALERTS_DISMISS_ALL: {
            return {
                ...state,
                alerts: []
            };
        }

        default:
            return state;
    }
}
