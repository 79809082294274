import * as Sentry from '@sentry/browser';
import { replace } from 'connected-react-router';
import { login, register, logout, setApiRequestToken, unsetApiRequestToken } from 'api/auth';
import { getAuthedProfile, linkSocialMedia, unLinkSocialMedia, updateUserProfile } from 'api/user';
import { getInvitationAuthUrl } from 'api/invitation';
import { setAuthToken, getAuthToken, deleteAuthToken } from './utils/auth/helpers';
import { unsubscribeFcm } from './utils/alerts/helpers';
import { handleLoadUserCompanies, handleSelectedCompanyChange } from './company';
import { subscribeFcm, pushAlert, ALERT_TYPES } from './alerts';
import { AUTHENTICATION_TYPE, ROUTE_REDIRECT_AFTER_LOGIN, ROUTES } from 'config/constants';
import { parseUrl } from 'utils/helpers';

export const AUTH_METHODS = {
    REGISTER: 'register',
    LOGIN: 'login',
    INVITE: 'invite'
};

const AUTH_METHOD_FUNCTION = {
    [AUTH_METHODS.REGISTER]: register,
    [AUTH_METHODS.LOGIN]: login,
    [AUTH_METHODS.INVITE]: getInvitationAuthUrl
};

const IS_PROFILE_READY = 'IS_PROFILE_READY';

const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAILED_LOGIN = 'AUTH_FAILED_LOGIN';
const AUTH_FAILED_REGISTRATION = 'AUTH_FAILED_REGISTRATION';
const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
const UPDATE_PROFILE = 'UPDATE_PROFILE';

const UNLINK_SOCIAL_START = 'UNLINK_SOCIAL_START';
const UNLINK_SOCIAL = 'UNLINK_SOCIAL';

export const profileIsReady = () => ({
    type: IS_PROFILE_READY
});

const authStart = (method) => ({
    type: AUTH_START,
    method
});

const authSuccess = (profile) => ({
    type: AUTH_SUCCESS,
    profile
});

const loginError = () => ({
    type: AUTH_FAILED_LOGIN
});

const authLogoutStart = () => ({
    type: AUTH_LOGOUT_START
});

const authLogoutSuccess = () => ({
    type: AUTH_LOGOUT_SUCCESS
});

const updateProfileStart = () => ({
    type: UPDATE_PROFILE_START
});

const updateProfile = (profile) => ({
    type: UPDATE_PROFILE,
    profile
});

const unlinkSocialStart = () => ({
    type: UNLINK_SOCIAL_START
});

const unlinkSocial = (socialType) => ({
    type: UNLINK_SOCIAL,
    socialType
});

export function handleAuthStart(provider, type, ...args) {
    return async dispatch => {
        dispatch(authStart(provider));

        try {
            const {
                data: {
                    redirect_url
                }
            } = await AUTH_METHOD_FUNCTION[type](provider, ...args);

            window.location.href = redirect_url;
        } catch (err) {
            dispatch(loginError());
            console.error(err);
        }
    };
}

export function handleAuthCallback(redirectRoute, noRedirection) {
    return async (dispatch, getState) => {
        const {
            router: {
                location
            }
        } = getState();

        const {
            isSuccess,
            token,
            provider,
            error
        } = parseUrl(`${location.pathname}${location.search}`).query;

        const isLogin = location.pathname === ROUTES.LOGIN;

        if (isSuccess === undefined || isLogin === undefined) {
            return;
        }

        if (!isSuccess) {
            dispatch(pushAlert({
                text: `${isLogin ? 'Login' : 'Sign-up'} failed`,
                subText: error ? decodeURIComponent(error) : 'Something went wrong',
                type: ALERT_TYPES.ERROR
            }));

            return;
        }

        dispatch(authStart(provider));

        try {
            setAuthToken(token);
            setApiRequestToken(token);
            const fetchCompanyPromise = dispatch(handleLoadUserCompanies());
            const fetchProfilePromise = getAuthedProfile();
            const allResponses = await Promise.all([fetchProfilePromise, fetchCompanyPromise]);
            const { data } = allResponses[0];

            dispatch(authSuccess(data));
            dispatch(subscribeFcm(true));
            !noRedirection && dispatch(replace(redirectRoute || ROUTE_REDIRECT_AFTER_LOGIN));
        } catch (err) {
            dispatch(handleLogout(true));
            dispatch(loginError());
            console.error(err);
        }
    };
}

export function handleInvitedAuth(token, companyId) {
    return async (dispatch) => {
        try {
            setAuthToken(token);
            unsetApiRequestToken();
            setApiRequestToken(token);
            const fetchCompanyPromise = dispatch(handleLoadUserCompanies());
            const fetchProfilePromise = getAuthedProfile();
            const allResponses = await Promise.all([fetchProfilePromise, fetchCompanyPromise]);
            const { data } = allResponses[0];

            dispatch(handleSelectedCompanyChange(companyId));
            dispatch(authSuccess(data));
            dispatch(subscribeFcm(true));
        } catch (err) {
            dispatch(handleLogout(true));
            console.error(err);
        }
    };
}

export function handleAutoLogin() {
    return async (dispatch) => {
        try {
            const token = getAuthToken();

            if (token !== null) {
                setApiRequestToken(token);
                const { data } = await getAuthedProfile();

                dispatch(authSuccess(data));
                dispatch(subscribeFcm());
            }
        } catch (err) {
            dispatch(handleLogout(true));
            console.error(err);
        } finally {
            dispatch(profileIsReady());
        }
    };
}

export function handleLogout(isAutoLogout = false) {
    return async (dispatch) => {
        dispatch(authLogoutStart());

        const isAuthed = !!getAuthToken();

        deleteAuthToken();
        unsubscribeFcm();

        try {
            if (isAuthed) {
                await logout();
            }
        } catch (err) {
            // logout fails when called from unauthed client
            // therefor when called from invalid login
            // silently catch this error
            // in anyway the failing of this api at this point should not cause any issues
        } finally {
            unsetApiRequestToken();

            if (isAutoLogout === true) {
                dispatch(authLogoutSuccess());
            } else {
                window.location = process.env.REACT_APP_URL || window.location.origin;
            }
        }
    };
}

export function handleUpdateUserProfile(id, params) {
    return async (dispatch) => {
        dispatch(updateProfileStart());

        try {
            const { data } = await updateUserProfile(id, params);

            dispatch(updateProfile(data));
        } catch (err) {
            dispatch(updateProfile({}));
            console.error(err);
        }
    };
}

export function handleLinkSocial(user, type) {
    return async (dispatch, getState) => {
        if (!user) return;

        dispatch(updateProfileStart());
        const { auth } = getState();
        const { profile } = auth;
        let newProfile = {};

        console.log(user);

        try {
            await linkSocialMedia({
                provider: type,
                [type === AUTHENTICATION_TYPE.LINKEDIN ? 'code' : 'token']: user._token.accessToken
            });

            if (type === AUTHENTICATION_TYPE.LINKEDIN) {
                newProfile = {
                    ...profile,
                    data: { ...profile?.data, profile: { ...profile?.data?.profile, linkedinAccessToken: user._token.accessToken } }
                };
            } else if (type === AUTHENTICATION_TYPE.FACEBOOK) {
                newProfile = {
                    ...profile,
                    data: { ...profile?.data, profile: { ...profile?.data?.profile, facebookAccessToken: user._token.accessToken } }
                };
            } else if (type === AUTHENTICATION_TYPE.ZOOM) {
                newProfile = {
                    ...profile,
                    data: { ...profile?.data, profile: { ...profile?.data?.profile, zoomAccessToken: user._token.accessToken } }
                };
            }

            dispatch(updateProfile(newProfile));
        } catch (err) {
            dispatch(updateProfile({}));
            console.error(err);
        }
    };
}

export function handleUnlinkSocial(type) {
    return async (dispatch, getState) => {
        dispatch(updateProfileStart());
        const { auth } = getState();
        const { profile } = auth;
        let newProfile = {};

        if (type === AUTHENTICATION_TYPE.LINKEDIN) {
            newProfile = {
                ...profile,
                data: { ...profile?.data, profile: { ...profile?.data?.profile, linkedinId: null, linkedinAccessToken: null } }
            };
        } else if (type === AUTHENTICATION_TYPE.FACEBOOK) {
            newProfile = {
                ...profile,
                data: { ...profile?.data, profile: { ...profile?.data?.profile, facebookId: null, facebookAccessToken: null } }
            };
        } else if (type === AUTHENTICATION_TYPE.ZOOM) {
            newProfile = {
                ...profile,
                data: { ...profile?.data, profile: { ...profile?.data?.profile, zoomId: null, zoomAccessToken: null } }
            };
        }

        try {
            await unLinkSocialMedia(type);

            dispatch(updateProfile(newProfile));
        } catch (err) {
            dispatch(updateProfile({}));
            console.error(err);
        }
    };
}

const INITIAL_STATE = {
    isProfileReady: false,
    isAuthenticating: false,
    isUpdatingProfile: false,
    isAuthed: false,
    isLoggingOut: false,

    profile: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case IS_PROFILE_READY:
            return {
                ...state,
                isProfileReady: true
            };

        case AUTH_START:
            return {
                ...state,
                isAuthenticating: true,
                authMethod: action.method
            };

        case AUTH_FAILED_LOGIN:
        case AUTH_FAILED_REGISTRATION:
            return {
                ...state,
                isAuthenticating: false
            };

        case AUTH_SUCCESS: {
            const { profile } = action;
            const { id, email } = profile;

            Sentry.configureScope((scope) => {
                scope.setUser({ id, email });
            });

            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                profile
            };
        }

        case UPDATE_PROFILE_START:
            return {
                ...state,
                isUpdatingProfile: true
            };

        case UPDATE_PROFILE: {
            const { profile } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    ...profile
                }
            };
        }

        case AUTH_LOGOUT_START:
            return {
                ...state,
                isLoggingOut: true
            };

        case AUTH_LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE,
                isProfileReady: true
            };

        default:
            return state;
    }
};
