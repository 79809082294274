import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faQuestionCircle, faAt } from '@fortawesome/free-solid-svg-icons';
import NotificationsMenu from '../Notifications/NotificationsMenu';
import SocialMenu from '../Social/SocialMenu';
import Faq from '../Faq/Faq';
import Support from '../Support/Support';
import Company from '../Company/Company';
import Description from '../Description/Description';
import ReferralCode from '../ReferralCode/ReferralCode';
import NewCompanyModal from './NewCompanyModal';

export default class SlideMenu extends Component {
    static propTypes = {
        showNotifications: PropTypes.bool.isRequired,
        showMenu: PropTypes.bool.isRequired,
        closeMenu: PropTypes.func.isRequired,
        toggleNotifications: PropTypes.func.isRequired,
        activeTab: PropTypes.string.isRequired,
        selectTab: PropTypes.func.isRequired,
        toggleSocial: PropTypes.func.isRequired,
        showSocial: PropTypes.bool.isRequired,
        showFaq: PropTypes.bool.isRequired,
        toggleFaq: PropTypes.func.isRequired,
        showSupport: PropTypes.bool.isRequired,
        toggleSupport: PropTypes.func.isRequired,
        showDescription: PropTypes.bool.isRequired,
        toggleDescription: PropTypes.func.isRequired,
        showReferralCode: PropTypes.bool.isRequired,
        toggleReferralCode: PropTypes.func.isRequired,

        isFetching: PropTypes.bool,
        account: PropTypes.object,
        selectedCompany: PropTypes.object,
        companies: PropTypes.array,
        onLogout: PropTypes.func,
        company: PropTypes.object,
        preferences: PropTypes.object,
        notifications: PropTypes.object,
        totalUnreadNotifications: PropTypes.number,
        onDeleteCompany: PropTypes.func,
        history: PropTypes.object,
        onUpdatePreference: PropTypes.func,
        onReadNotification: PropTypes.func,
        onDeleteNotification: PropTypes.func,
        onDeleteAccount: PropTypes.func,
        onUpdateProfile: PropTypes.func,
        onPublish: PropTypes.func,
        onUnpublish: PropTypes.func,
        onQuitCompany: PropTypes.func,
        onGrantPermissions: PropTypes.func,
        onSendClientsInvite: PropTypes.func,
        isSubmitLoading: PropTypes.object,
        formSuccess: PropTypes.object,
        formError: PropTypes.object,
        onItemSelect: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            isModalVisible: false,
            company: {}
        };

        this.menuItemList = null;
    }

    componentDidMount() {
        this.handleMenuItemListListeners(false);
    }

    componentWillUnmount() {
        this.handleMenuItemListListeners(true);
    }

    handleMenuItemListListeners = isUnmount => {
        !isUnmount && (this.menuItemList = document.querySelector('nav.bm-item-list'));
        this.menuItemList[`${isUnmount ? 'remove' : 'add'}EventListener`]('click', this.handleMenuItemListClick);
    }

    handleMenuItemListClick = e => {
        const { closeMenu } = this.props;
        const { target } = e;
        const menuItemList = this.menuItemList;

        if (target === menuItemList) {
            closeMenu();
        }
    }

    toggleModal = () => {
        this.setState({
            isModalVisible: !this.state.isModalVisible
        });
    }

    renderCompanies = ({ toggleDescription, onDeleteCompany }) => {
        const { history, companies, onSendClientsInvite, isSubmitLoading, formSuccess, onItemSelect, closeMenu } = this.props;

        return companies.map((item, i) => (
            <Company
                history={history}
                isCreator={true}
                toggleDescription={toggleDescription}
                toggleReferralCode={this.props.toggleReferralCode}
                title={item.name}
                company={item}
                key={i}
                onItemSelect={onItemSelect}
                onCloseMenu={closeMenu}
                onDeleteCompany={onDeleteCompany}
                onSendClientsInvite={onSendClientsInvite}
                isSubmitLoading={isSubmitLoading}
                formSuccess={formSuccess}/>
        ));
    }

    render() {
        const {
            showNotifications, showMenu, closeMenu, toggleNotifications, isFetching,
            activeTab, selectTab, toggleSocial, showSocial, showFaq, toggleFaq, selectedCompany,
            showSupport, toggleSupport, showDescription, toggleDescription, showReferralCode, toggleReferralCode, preferences,
            onUpdatePreference, onReadNotification, onDeleteNotification, onDeleteCompany, history, isSubmitLoading,
            formSuccess, notifications, totalUnreadNotifications, formError, onUnlinkSocial
        } = this.props;

        return (
            <Menu
                right={true}
                isOpen={showMenu}
                onClose={closeMenu}
                width={showFaq ? 450 : showDescription ? 1072 : 352}>
                {!showNotifications && !showSocial && !showFaq && !showSupport && !showDescription &&!showReferralCode
                    ? <div className='Blue-Header__menu'>
                        <p className='Blue-Header__menu-title'>
                            Profile
                        </p>
                        <div className='Blue-Header__menu-item' onClick={!isFetching && toggleNotifications}>
                            <span className='Blue-Header__menu-badge'>{totalUnreadNotifications}</span>
                            <p>Notifications</p>
                        </div>
                        <div className='Blue-Header__menu-item' onClick={toggleSocial}>
                            <FontAwesomeIcon icon={faShareSquare} color='#58595B' size={'lg'} />
                            <p>Social medias</p>
                        </div>
                        <div className='Blue-Header__menu-item' onClick={toggleFaq}>
                            <FontAwesomeIcon icon={faQuestionCircle} color='#58595B' size={'lg'} />
                            <p>FAQ</p>
                        </div>
                        <div className='Blue-Header__menu-item' onClick={toggleSupport}>
                            <FontAwesomeIcon icon={faAt} color='#58595B' size={'lg'} />
                            <p>Support</p>
                        </div>
                        <div className='position-relative'>
                            <p className='Blue-Header__menu-title'>Companies</p>
                            <span className='Blue-Header__menu-more' onClick={this.toggleModal}>+</span>
                        </div>
                        <div className='pb-70'>
                            { this.renderCompanies({ toggleDescription, toggleReferralCode, onDeleteCompany }) }
                        </div>
                        <NewCompanyModal
                            onClick={() => history.push('/company/create')}
                            isModalVisible={this.state.isModalVisible}
                            toggleModal={this.toggleModal} />
                    </div>
                    : showNotifications ? <NotificationsMenu
                        selectedCompany={selectedCompany}
                        isFetching={isFetching}
                        preferences={preferences}
                        notifications={notifications}
                        onUpdatePreference={onUpdatePreference}
                        onReadNotification={onReadNotification}
                        onDeleteNotification={onDeleteNotification}
                        toggleNotifications={toggleNotifications}
                        activeTab={activeTab}
                        selectTab={selectTab}/>
                        : showSocial ? <SocialMenu
                            account={this.props.account}
                            toggleSocial={toggleSocial} />
                            : showFaq ? <Faq toggleFaq={toggleFaq}/>
                                : showSupport ? <Support
                                    account={this.props.account}
                                    selectedCompany={this.props.selectedCompany}
                                    toggleSupport={toggleSupport}
                                    onLogout={this.props.onLogout}
                                    onDeleteAccount={this.props.onDeleteAccount}
                                    isSubmitLoading={isSubmitLoading}
                                    formSuccess={formSuccess}/>
                                    : showDescription ? <Description
                                        account={this.props.account}
                                        company={this.props.company}
                                        toggleDescription={toggleDescription}
                                        onPublish={this.props.onPublish}
                                        onUpdateProfile={this.props.onUpdateProfile}
                                        onUnpublish={this.props.onUnpublish}
                                        onQuitCompany={this.props.onQuitCompany}
                                        onGrantPermissions={this.props.onGrantPermissions}
                                        isSubmitLoading={isSubmitLoading}
                                        formSuccess={formSuccess}
                                        formError={formError}/>
                                        : showReferralCode ? <ReferralCode
                                        account={this.props.account}
                                        selectedCompany={this.props.company}
                                        toggleReferralCode={toggleReferralCode}/>
                                        : null}
            </Menu>
        );
    }
}
