import { ELOQUENT } from 'config/constants';

/**
 * Fetch categories
 *  @param {String} coachId
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function getCategories(coachId, cancelKey) {
    return ELOQUENT.Category.getAll({ coach_id: coachId }, cancelKey);
}

export function getCMSCategory(cancelKey) {
    return ELOQUENT.Category.getAll({ cms: true }, cancelKey);
}

export function createCategory(data, cancelKey) {
    return ELOQUENT.Category.createOne(data, cancelKey);
}

export function updateCategory(data, cancelKey) {
    return ELOQUENT.Category.updateOne(data, cancelKey);
}

export function deleteCategory(id, cancelKey) {
    return ELOQUENT.Category.deleteOne(id, cancelKey);
}

export function creteCategoryIcon(data, cancelKey) {
    return ELOQUENT.CategoryIcon.createOne(data);
}

export function getCategoryIcons(cancelKey) {
    return ELOQUENT.CategoryIcon.getAll(cancelKey);
}

export function getOneCategory(categoryId, cancelKey) {
    return ELOQUENT.Category.getOne(categoryId, cancelKey);
}

export function getEventsCount(id, cancelKey) {
    return ELOQUENT.Category.getEventsCount(id, cancelKey);
}
