import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewClientsModal from './NewClientsModal';
import { USER_PERMISSIONS } from 'config/constants';
import SafeImage from 'components/SafeImage/SafeImage';
import DeleteCompanyModal from 'components/Modals/DeleteCompanyModal/DeleteCompanyModal';

export default class Company extends Component {
    static propTypes = {
        history: PropTypes.object,
        toggleDescription: PropTypes.func.isRequired,
        toggleReferralCode: PropTypes.func.isRequired,
        isCreator: PropTypes.bool,
        title: PropTypes.string.isRequired,
        company: PropTypes.object,
        onDeleteCompany: PropTypes.func,
        onCloseMenu: PropTypes.func,
        onSendClientsInvite: PropTypes.func,
        isSubmitLoading: PropTypes.object,
        formSuccess: PropTypes.object,
        onItemSelect: PropTypes.func
    }

    state = {
        isVisible: false,
        isModalVisible: false,
        isNewClientsModal: false,
        coachHasCompanyPermission: false
    }

    componentDidMount() {
        const { company } = this.props;
        const { isOwner, userPermissions } = company;

        if (!isOwner) {
            if (userPermissions.includes(USER_PERMISSIONS.COMPANY_DESCRIPTION) || userPermissions.includes(USER_PERMISSIONS.COMPANY_COACHES) ||
                userPermissions.includes(USER_PERMISSIONS.COMPANY_CLIENTS) || userPermissions.includes(USER_PERMISSIONS.COMPANY_CLIENTS_OF_COACH)) {
                this.setState({
                    coachHasCompanyPermission: true
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.formSuccess?.deleteCompany) {
                this.setState({ isModalVisible: false });
            }
        }
    }

    toggleCompany = () => {
        this.setState({
            isVisible: !this.state.isVisible
        });
    }

    toggleModal = () => {
        this.setState({
            isModalVisible: !this.state.isModalVisible
        });
    }

    toggleNewClientsModal = () => {
        this.setState({
            isNewClientsModalVisible: !this.state.isNewClientsModalVisible
        });
    }

    openCompanyPage = () => {
        const { history, company, onItemSelect, onCloseMenu } = this.props;

        onItemSelect(company.id);
        onCloseMenu();
        history.push('/company');
    }

    render() {
        const { coachHasCompanyPermission } = this.state;
        const {
            toggleDescription, toggleReferralCode, company, onDeleteCompany, onSendClientsInvite,
            isSubmitLoading, formSuccess
        } = this.props;

        return (
            <div>
                <div className='Blue-Header__company' onClick={this.toggleCompany}>
                    <span className='d-flex flex-ac'>
                        <SafeImage
                            className='Blue-Header__company-avatar'
                            alt={company.name}
                            src={company.logo} />
                        <span className='Blue-Header__company-name'>{this.props.title}</span>
                    </span>
                    {this.state.isVisible
                        ? <FontAwesomeIcon className='Blue-Header__company-chevron' icon={faChevronUp} color='#58595B'
                            size={'sm'} /> : <FontAwesomeIcon className='Blue-Header__company-chevron' icon={faChevronDown} color='#58595B'
                            size={'sm'} />
                    }
                </div>
                {this.state.isVisible &&
                <>
                    {company.isOwner ? <>
                        <div className='Blue-Header__company-item' onClick={this.toggleModal}>Delete</div></>
                        : <>
                            <div className='Blue-Header__company-item' onClick={() => toggleDescription(this.props.company)}>Description</div>
                        </>
                    }
                    <div className='Blue-Header__company-item' onClick={() => toggleReferralCode(this.props.company)}>Referral Code</div>
                </>}

                <DeleteCompanyModal
                    company={company}
                    isModalVisible={this.state.isModalVisible}
                    toggleModal={this.toggleModal}
                    onDeleteCompany={onDeleteCompany}
                    isSubmitLoading={isSubmitLoading}
                    formSuccess={formSuccess}/>

                <NewClientsModal
                    onSendClientsInvite={onSendClientsInvite}
                    isNewClientsModalVisible={this.state.isNewClientsModalVisible}
                    toggleNewClientsModal={this.toggleNewClientsModal} />
            </div>
        );
    }
}
